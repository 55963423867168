import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/seo';
import headShot from '../images/headshot-small.jpg';

const IndexPage = () => (
  <div className="h-100 d-flex justify-content-center align-items-center">
    <SEO title="Luke Ulrich" />
    <div className="text-center" style={{maxWidth: 300}}>
      <img src={headShot} className="rounded-lg shadow" />
      <h1 className="font-brand mt-2">Luke Ulrich</h1>
      <Link to="/resume">Resume</Link>

      <hr />

      <blockquote className="blockquote">
        <p className="mb-0">If you are not embarrassed by the first version of your product, you’ve launched too late.</p>
        <footer className="blockquote-footer">Reid Hoffman</footer>
      </blockquote>
    </div>
  </div>
);

export default IndexPage;
